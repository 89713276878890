.djc--view-article {
    width: 100%;
    height: 100%;

    .content {
        max-width: 840px;
        margin: 0 auto;
        margin-top: 16px;
        padding: 16px;
    }

    .article-title {
        h1 {
            font-weight: 500;
            font-size: 48px;
            color: $primary;
        }
        h3 {
            font-weight: 500;
            font-size: 24px;
            color: rgba($primary, 0.5);
        }
        margin-bottom: 32px;
    }

    .article-image {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 32px;

        .img-container {
            width: 100%;
            min-height: 100px;
            max-height: 600px;
            height: auto;
            margin-bottom: 8px;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                background-size: cover;
            }
        }

        .article-image-meta {
            color: rgba($dark, 0.6);
        }
    }

    .article-meta {
        margin-bottom: 32px;
    }
}

@media (max-width: 768px) {
    .djc--view-article {
        .content {
            padding: 8px;
        }
    }
}

@media (max-width: 425px) {
    .djc--view-article {
        .content {
            padding: 4px;
        }
    }
}