.djc--view-settings {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    z-index: 1;

    .settings--root {
        width: 100%;
        height: 100%;
        z-index: 1;

        .container-fluid {
            padding: 0 !important;
            z-index: 1;
        }
    }

    .gravatar-container {
        .gravatar {
            height: 64px;
            width: 64px;
        }
    }

    .page-section {
        margin-bottom: 32px;
        width: 100%;

        h3 {
            font-size: 22px;
            width: 100%;
            display: block;
            border-bottom: 1px solid rgba($dark, 0.1);
            padding-bottom: 8px;
            margin-bottom: 32px;
        }
    }
}