@import './utils/colors';
@import './utils/variables';
@import './utils/text-align';

@import './components/header';
@import './components/header.homepage';
@import './components/button';
@import './components/hero';
@import './components/footer';
@import './components/modal';
@import './components/logged_drawer';
@import './components/error';
@import './components/codex-editor';
@import './components/dropdown';
@import './components/alert-message';
@import './components/scoreboard';
@import './components/nav';

@import './markdown/markdown';

@import './views/homepage';
@import './views/about';
@import './views/login';
@import './views/signup';
@import './views/settings';
@import './views/user';
@import './views/campaigns';
@import './views/campaigns_create';
@import './views/campaign_detail';
@import './views/campaign_participate';
@import './views/campaigns_done';
@import './views/contact_us';
@import './views/cgi';
@import './views/articles';
@import './views/article_detail';
@import './views/article_create';
@import './views/reset-password';
@import './views/reset-phone-guild';
@import './views/admin';

@import './overrides/splitjs';
@import './overrides/codemirror';
@import './overrides/mui';
@import './overrides/stepper';

$theme-colors: (
  "primary": $primary,
  "body-bg": $background
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
// "node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "NotoMono";
  src: url("./fonts/NotoMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "FiraCode";
  src: url("./fonts/FiraCode-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "FiraMono";
  src: url("./fonts/FiraMono-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;

  overflow-x: hidden;
  background-color: $background;


  font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif;
}

a {
  color: $primary;
  font-weight: bold;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    cursor: default !important;
  }

  &:hover:not(.disabled) {
    text-decoration: underline;
    color: $primary;
  }
}

.green-circle, .red-circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.red-circle {
  background-color: red;
}
.green-circle {
  background-color: rgb(3, 216, 3);
}

.inline-block-important {
  display: inline-block !important;
}

.bold {
  font-weight: bold;
}

.orange {
  color: $primary;
}

.primary {
  color: $primary;
}

.primary-secondary {
  color: $primary-secondary;
}

.dark {
  color: $dark;
}

.djc--page-content {
  padding-top: $header-height;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.form-control:not([disabled]):not([readonly]):focus {
  border-color: $primary;
  box-shadow: 0 0 0 0.2rem rgba($primary,.25);
}

.form-control:read-only:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem transparent;
}

.fullwidth {
  width: 100% !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}

.mt-1 {
  margin-top: 1px !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-64 {
  margin-top: 64px !important;
}

.mr-1 {
  margin-right: 1px !important;
}
.mr-2 {
  margin-right: 2px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-16 {
  margin-right: 16px !important;
}

.ml-1 {
  margin-left: 1px !important;
}
.ml-2 {
  margin-left: 2px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.ml-16 {
  margin-left: 16px !important;
}

.fl-r {
  float: right;
}

.fl-l {
  float: left;
}

.no-pad {
  padding: 0;
}

p {
  margin: 0;
}

.bg-blank {
  background-color: white;
}

.pd-0 {
  padding: 0px !important;
}
.pd-1 {
  padding: 1px;
}
.pd-2 {
  padding: 2px;
}
.pd-4 {
  padding: 4px;
}
.pd-8 {
  padding: 8px;
}
.pd-16 {
  padding: 16px;
}
.pd-32 {
  padding: 32px;
}
.pd-64 {
  padding: 64px;
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  background-color: rgba($dark, 0.1);
}

.error {
  color: #f44336;
}

.block {
  display: block;
}

.no-margin {
  margin: 0;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: rgba($dark, 0.3);
}
::-webkit-scrollbar-thumb:hover{
  background: $dark;
}

::-webkit-scrollbar-track{
  background: #ffffff;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}


.green {
  color: green;
}

.red {
  color: $red;
}

.tsp-2 {
  color: rgba($dark, 0.2);
}
.tsp-3 {
  color: rgba($dark, 0.3);
}
.tsp-4 {
  color: rgba($dark, 0.4);
}
.tsp-5 {
  color: rgba($dark, 0.5);
}
.tsp-6 {
  color: rgba($dark, 0.6);
}

.no-padding-top {
  padding-top: 0;
}