.step-trigger {
    &:hover {
        background-color: transparent !important;
        cursor: default !important;
    }
}

.bs-stepper-label {
    color: $light !important;
}

.bs-stepper-circle {
    background-color: $light !important;
}

.active {
    .bs-stepper-label {
        color: $primary !important;
    }

    .bs-stepper-circle {
        background-color: $primary !important;
    }
}