.djc--view-campaigns-create {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        width: 100%;
        max-width: 960px;
        margin: 32px;
    }

    .campaign-top-title {
        color: $dark;
        font-size: 24px;
        margin-bottom: 16px;
    }

    @media (max-width: 768px) { 
        .campaign-top-title {
            padding-left: 16px;
        }
    }

    .section {
        &>h4 {
            display: block;
            color: $dark;
            font-size: 18px;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba($dark, 0.2);
            margin-bottom: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 32px;
        }

        .row {
            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .input {
                .MuiTextField-root {
                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }
            }

            .grid-container {
                height: 500px;
            }

            .thumbnail-container {
                padding: 10px;
                background: white;
                box-sizing: border-box;
                border: 1px dashed;
                overflow: auto;
                max-width: 100%;
                width: 100%;
                float: right;

                .thumbnail {
                    width: 100%;
                    padding-bottom: 56.25%;
                    background: rgba($dark, 0.2);
                    position: relative;

                    img {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        @media (max-width: 768px) { 
            .input {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }

            .thumbnail-container {
                padding: 10px;
                background: white;
                box-sizing: border-box;
                border: 1px dashed;
                overflow: auto;
                max-width: 100%;
                width: 100%;

                .thumbnail {
                    width: 100%;
                    padding-bottom: 56.25%;
                    background: rgba($dark, 0.2); /** <-- For the demo **/
                }
            }
        }
    }
}