.djc--footer {
  width: 100%;
  height: $footer-height;
  // background-color: $dark;
  background-color: inherit;
  padding: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .djc--footer-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    &>a, span {
      text-align: center;
      color: rgba(142, 142, 142,1);
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      display: block;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &.legals {
      a {
        margin-left: 4px;
      }
    }
  }

  &.dark {
    background-color: #04020a;
    
    .djc--footer-section {

      &>a, span {
        color: rgb(228, 207, 231);
      }
    }
  }
}


@media (max-width: 576px) {
  .djc--footer {
    padding: 8px;

    .djc--footer-section {
      &>a, span {
        font-size: 10px;
      }

      &.legals {
        a {
          margin-left: 4px;
          margin-right: 4px;
        }
      }
    }
  }
}