.react-codemirror2 {
    height: 100%;

    .CodeMirror {
        height: 100%;
    }
}

.CodeMirror-line {
    * {
        font-family: monospace !important;
        // font-size: 14px;
    }
}