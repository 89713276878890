.djc--view-campaigns-done {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark;

    .content {
        width: 100%;
        max-width: 960px;
        margin: 32px;
    }

    .campaign-top-title {
        color: $dark;
        font-size: 24px;
        margin-bottom: 16px;
    }

    @media (max-width: 768px) { 
        .campaign-top-title {
            padding-left: 16px;
        }
    }

    .better-than-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .left, .right {
            width: 50%;
            height: 100px;
        }

        color: rgba($dark, 0.8);
        
        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex: 1;
            flex-grow: 1;

            svg {
                width: 80%;
                height: 80%;
                color: $primary
            }
        }

        .left {
            display: flex;
            flex-direction: column;

            .capitalize {
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.8px;
            }
    
            .better-percent {
                color: $primary;
                font-size: 32px;
                font-weight: 500;
            }
        }
    }

    .challenge-results {
        width: 100%;
        height: 100%;
        margin-top: 16px;

        h4 {
            font-size: 18px;
        }

        .stats-container {
            width: 100%;
            border: 1px solid rgba($dark, 0.1);
            padding: 16px;

            .stat {
                width: 100;

                .stat-name {
                    font-size: 14px;
                }

                .stat-value {
                    text-align: end;
                }

                .progress {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 0;
                    background-color: transparent;

                    .rect {
                        height: 100%;
                        width: 8%;
                        background-color: rgba($dark, 0.2);

                        &.filled {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }

    .stats-section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        .stats {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100;

            .stat-name {
                color: $dark;
                font-size: 14px;
                font-weight: 500;

                min-width: 100px;
                margin-right: 16px;
            }

            svg {
                margin-right: 16px;
            }
        }
    }

    .row {
        &>div {
            &:not(:last-child) {
                border-right: 1px solid rgba($dark, 0.2);
            }
        }
    }

    @media (max-width: 768px) {
        .row {
            &>div {
                &:not(:last-child) {
                    border: none;
                }

                margin-bottom: 32px;
            }
        }

        .better-than-section {
            .right {
                justify-content: flex-end;

                svg {
                    width: auto;
                    height: 100%;
                    color: $primary
                }
            }
        }
    }

    .section {
        &>h4 {
            display: block;
            color: $dark;
            font-size: 18px;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba($dark, 0.2);
            margin-bottom: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 32px;
        }

        .row {
            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .input {
                .MuiTextField-root {
                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }
            }

            .grid-container {
                height: 500px;
            }

            .thumbnail-container {
                padding: 10px;
                background: white;
                box-sizing: border-box;
                border: 1px dashed;
                overflow: auto;
                max-width: 100%;
                width: 100%;
                float: right;

                .thumbnail {
                    width: 100%;
                    padding-bottom: 56.25%;
                    background: rgba($dark, 0.2);
                    position: relative;

                    img {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        @media (max-width: 768px) { 
            .input {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }

            .thumbnail-container {
                padding: 10px;
                background: white;
                box-sizing: border-box;
                border: 1px dashed;
                overflow: auto;
                max-width: 100%;
                width: 100%;

                .thumbnail {
                    width: 100%;
                    padding-bottom: 56.25%;
                    background: rgba($dark, 0.2); /** <-- For the demo **/
                }
            }
        }
    }
}