.djc--alert-message-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100000;
    background-color: rgba($dark, 0.6);

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px;
    padding-top: 64px;

    .box {
        display: block;
        background-color: white;
        height: fit-content;

        min-width: 40%;
        max-width: 640px;
        width: max-content;
    }

    .kind {
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 16px;
        color: white;

        &.alert {
            background-color: $red;
        }

        &.warning {
            background-color: $warning;
        }

        &.info {
            background-color: $primary;
        }

        .kind-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            width: 100%;

            i {
                font-size: 18px;
                margin-right: 16px;
            }
            h3 {
                font-size: 22px;
                line-height: 22px;
                margin: 0;
                padding: 0;
            }
        }
    }

    .content {
        padding: 16px;
    }

    .footer {
        button {
            width: auto !important;
            min-width: none !important;
            padding-left: 8px !important;
            padding-right: 8px !important;
            border: none !important;

            &.cancel-btn {
                background-color: transparent;
                border: none;
                color: rgba($dark, 0.4) !important;
                
                &:hover {
                    color: $dark !important;
                }
            }
        }
        
        &.info {
            .validate-btn {
                background-color: $primary;
                border: none;

                &:hover {
                    color: $primary !important;
                }
            }
        }

        &.alert {
            margin-bottom: 0 !important;

            .validate-btn {
                background-color: $red;
                border: none;

                &:hover {
                    color: $red !important;
                }
            }
        }

        &.warning {
            margin-bottom: 0 !important;

            .validate-btn {
                background-color: $warning;
                border: none;

                &:hover {
                    color: $warning !important;
                }
            }
        }

        padding: 8px;
        background-color: rgba($dark, 0.03);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .left, .right {
            display: flex;
            flex-direction: row;
            flex: 1;
            justify-content: flex-start;
        }

        .left {
            * {
                margin-right: 8px;
            }
        }

        .right {
            justify-content: flex-end;

            * {
                margin-left: 8px;
            }
        }

        .cancel-btn {
            background-color: transparent !important;
            color: $primary !important;
        }
    }
}