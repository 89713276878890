.djc--logged-drawer {
    width: 240px;
    height: 100%;
    background-color: $white;
    color: $dark;
    position: relative;
    z-index: 10000 !important;

    & svg {
        color: $dark;
    }

    & button {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
    }

    &>.content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 34px;
        top: 0;
        overflow-y: auto;
        z-index: 10000 !important;
    }
}

