.djc--view-campaign-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
        width: 100%;
        height: 500px;
        background-color: $dark;
        position: relative;

        &::after {
            content: "";

            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-color: rgba(64,72,82,.8);
            
            z-index: 2;
        }

        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            
            z-index: 1;
        }

        .header-desc {
            z-index: 3;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            color: white;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 42px;
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: 1px;
            }
        }
    }

    .tabs {
        width: 100%;
        border-bottom: 1px solid rgba($dark, 0.1);

        .tabs-comp {
            button {
                &:focus {
                    outline: none !important;
                }
            }
        }
    }

    .content {
        width: 100%;
        max-width: 1168px;
        margin: 32px;

        .code-visualization-container {
            position: relative;
            width: 100%;
            min-height: 100px;
            max-height: 500px;

            .react-codemirror2 {
                min-height: 100px;
                max-height: 500px;
                overflow-y: auto;
            }
        }

        .desc-col {
            .desc-section {
                color: $dark;
                margin-bottom: 32px;
                
                .tags {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-top: 16px;

                    span {
                        &:first-child {
                            width: auto;
                            margin-right: 8px;
                            padding: 2px 0;
                        }

                        &:not(:first-child) {
                            display: block;
                            padding: 2px 8px;
                            background-color: $purple;
                            margin-right: 4px;
                            margin-bottom: 4px;
                            color: white;
                        }
                    }
                }
                
                h3 {
                    font-size: 20px;
                    margin-bottom: 8px;
                    padding-bottom: 8px;
                    font-weight: 700;
                    border-bottom: 1px solid rgba($dark, 0.2);
                }
            }
        }

        .campaign-title {
            color: $dark;
            font-size: 24px;
            margin-bottom: 16px;
            text-transform: capitalize;
            padding-bottom: 8px;
            margin-bottom: 16px;
            border-bottom: 1px solid rgba($dark, 0.2);
        }
    }

    @media (max-width: 768px) {
        header {
            height: 300px;
        }

        .content {
            margin: 0;

            .desc-col {
                margin-bottom: 32px;

                .desc-section {
                    margin-bottom: 32px;

                    h3 {
                        border-color: transparent;
                        margin-bottom: 8px;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
