.djc--view-contact {
    width: 100%;
    height: 100%;

    display: flex;
    padding-top: 32px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        max-width: 1280px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 32px;
        margin-bottom: 64px;
    }

    .hero {
        color: $dark;
        margin-bottom: 64px;

        h4 {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 16px;
            color: $primary;
            text-transform: uppercase;
        }
    
        h1 {
            font-weight: bolder;
            margin-bottom: 32px;
        }

        p {
            margin: 0 auto;
            text-align: center;
            max-width: 600px;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 1.1px;
            color: rgba($dark, 0.8);
        }
    }

    .content {
        width: 100%;
        margin: 0 auto;
        min-height: 200px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .contact-us-left {
            height: 100%;
        }

        .contact-us-center {
            height: 100%;

            transform: scale(1.2);
        }

        .contact-us-right {
            height: 100%;
        }

        .form {
            padding: 16px;
            width: 100%;
            height: 100%;
            min-height: 400px;

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                height: 100%;
                padding: 8px;
                -webkit-box-shadow: 2px 2px 4px 4px rgba($dark, 0.2); 
                box-shadow: 2px 2px 4px 4px rgba($dark, 0.2);
                
                h3 {
                    color: $primary;
                }

                p {
                    color:rgba($dark, 0.8);
                    font-size: 14px;
                }

                i {
                    width: 64px;
                    height: 64px;
                    font-size: 64px;
                }
            }
        }
    }

    @media (max-width: 991.98px) {
        .content {
            .contact-us-center {
                transform: scale(1.1);
            }

            .form {
                padding: 8px;
            }
        }
    }

    @media (max-width: 767.98px) {
        .content {
            .contact-us-center {
                transform: scale(1);
            }

            .form {
                padding: 8px;
                min-height: 300px;

                margin-bottom: 16px;

                .content {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
};