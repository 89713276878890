.djc--view-login-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: -#{$header-height};
}

.djc--view-login-topbar {
  height: 64px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;

  &>h2 {
    color: $primary;
    font-size: 32px;
    font-weight: bold;
  }
}

.djc--view-login-form {
  width: 100%;
  max-width: 500px;
  margin-top: 64px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-size: 24px;
    margin-bottom: 32px;
  }

  &>.invalid-credentials {
    font-weight: 500;
    font-size: 12px;
    color: red;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
}

  &>form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &>.input {
      margin-bottom: 32px;
      max-width: 420px;
    }
  }

  &>.divider {
    width: 90%;
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 32px 0px;
  }
}

.djc--view-login-form-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.djc--view-login-form-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &>h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 16px 0;
  }
  &>button {
    width: 350px;
  }
}

.djc--view-login-form-legals {
  width: 320px;
  text-align: center;

  &>p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 768px) {
  .djc--view-login-form {
    margin-top: 8px;
  }
}