// $primary: #E58F65;
// $secondary: #A66B4F;
// $dark: #423C41;
// $light: #F4F6F5;
// $light2: #9CA5AE;
// $white: #FFF;
// $primary-secondary: #65BCE5;
// $purple: #5C4158;
// $background: #fafafa;
// $red: #dc3545;

$primary: #B1518E;
$secondary: #34125F;
$dark: #04020a;
$light: rgb(230, 217, 248);
$light2: #9CA5AE;
$white: #FFF;
$primary-secondary: #65BCE5;
$purple: #5C4158;
$background: #fafafa;
$red: #dc3545;
$warning: #f0ad49;

$gold: #FEE101;
$silver: #D7D7D7;
$copper: #A77044;
