.dropdown-menu {
    .dropdown-item {
        transition: all 0.1s ease-in-out;
        color: $dark;
        background-color: $white;

        &:hover {
            color: $primary;
            background-color: $white;
            text-decoration: none;
        }

        &:active {
            color: $white;
            background-color: $primary;
        }
    }   
}