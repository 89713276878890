.gutter {
    cursor: col-resize;
    position: relative;
    background-color: $background;
    box-sizing: border-box;
    transition: all 0.2s;

    &::after {
        content: "...";
        writing-mode: vertical-rl;
        text-align: center;
        text-orientation: mixed;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 17px;
        height: 100%;
    }

    &:hover {
        background-color: rgba($dark, 0.2);
        color: white;
    }
}