.djc--header {
  width: 100%;
  height: $header-height;
  position: fixed;
  top: 0;

  background-color: white;
  border-bottom: 1px solid rgba($dark, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  z-index: 1200;
  padding-right: 8px;
}

.djc--header-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: auto;
  height: $header-height;
  padding: 4px;
  margin-right: 8px;
  margin-left: 8px;

  img {
    display: inline-block;
    height: 100%;
    margin-right: 8px;
  }

  h1 {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
}

.djc--header-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.mobile-nav-btn {
  margin-left: auto;
  margin-right: 8px;
}

.djc--header-navbar {
  height: 100%;

  ol {
    text-decoration: none;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none !important;
    }

    li {
      min-width: 80px;
      max-width: 120px;
      height: 100%;
      line-height: $header-height;
      text-align: center;
      padding: 0 8px;
      position: relative;

      &.disabled {
        a {
          color: rgba($dark, 0.2) !important;
        }
        cursor: not-allowed !important;
      }

      .dropdown {
        background-color: #fcfcfc;
        border: 1px solid rgba($dark, 0.1);
        padding: 8px;

        visibility: hidden;
        opacity: 0;
        display: none;
        
        position: absolute;
        transition: all 0.5s ease-in-out;
        left: 7px;

        min-width: 200px;
        max-width: 500px;

        text-decoration: none;
        list-style-type: none;

        & > li {
          min-width: 100%;
          max-width: 100%;
          margin: 0;
          display: block;
          line-height: 20px;
          width: 100%;
          height: 20px;
          font-size: 14px;
          color: rgba($dark, 0.8);

          text-align: left;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          a {
            display: inline-block;
            width: auto;
            text-decoration: none !important;

            &.active {
              color: $primary;
              
              &::after {
                height: 0px;
              }
            }
          }
        }
      }

      &:hover > .dropdown, & > .dropdown:hover {
        visibility: visible;
        opacity: 1;
        display: block;
      }

      a {
        display: block;
        height: 100%;
        color: rgba($dark, 0.4);
        position: relative;
        font-weight: bold;
  
        transition: all 0.1s;

        & > .fa {
          display: inline;
          padding-left: 4px;
        }
  
        &:hover {
          color: rgba($primary, 0.8);
          cursor: pointer;
  
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: rgba($primary, 0.8);
          }
        }
  
        &.active {
          color: $primary;
          cursor: pointer;
  
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: $primary;
          }
        }
      }
    }
  }
}

.djc--header-buttons {
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: right;
  margin: 0 8px 0 auto;

  .sign-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;

    button {
      margin-right: 16px;
    }
  }
}

.left-drawer {
  width: 90vw;
  padding: 16px;
  .left-drawer-nav {
    ol {
      text-decoration: none;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      margin: 0;
      padding: 0;
  
      a {
        text-decoration: none !important;
  
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
      
      li {
        width: 100%;
        height: 100%;
        line-height: $header-height;
        text-align: center;
        color: rgba($dark, 0.4);
        position: relative;
        font-weight: bold;
  
        transition: all 0.1s;
  
        &:hover {
          color: rgba($primary, 0.8);
          cursor: pointer;
  
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: rgba($primary, 0.8);
          }
        }
  
        &.active {
          color: $primary;
          cursor: pointer;
  
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: $primary;
          }
        }
  
        &.disabled {
          color: rgba($dark, 0.2) !important;
          cursor: not-allowed !important;
        }
      }
    }

    .dropdown {
      margin-top: 16px;
      margin-bottom: 16px;
      text-decoration: none;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      li {
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        display: block;
        line-height: 26px;
        width: 100%;
        height: 26px;
        font-size: 16px;
        color: rgba($dark, 0.8);

        &:hover {
          a {
            color: rgba($primary, 0.8);
          }
          cursor: pointer;
  
          &::after {
            content: '';
            height: 0;
          }
        }

        text-align: left;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        a {
          display: inline-block;
          width: auto;
          color: rgba($dark, 0.4);

          &.active {
            color: $primary;
          }
        }
      }
    }
  }
}