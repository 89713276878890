.codex-editor {
    .codex-editor__redactor {
        padding-bottom: 0 !important;

        .ce-block {
            .ce-block__content {
                max-width: 90%;
            }
        }
    }

    .ce-toolbar.ce-toolbar--opened {
        .ce-toolbar__content {
            max-width: 90%;
        }
    }
}

.code-editor-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    .fixed-wrapper {
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        bottom: 0px;

        .code-editor-btn-copy {
            position: absolute;
            right: 32px;
            top: 16px;
            width: 32px;
            height: 32px;
            z-index: 100;

            button {
                color: white;
            }
        }
    }
}