.djc--hero {
  width: 100%;
  height: 500px;
  background-color: $purple;
  padding: 64px;
}

.djc--hero-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.djc--hero-content-description {
  width: 670px;

  &>h2 {
    color: white;
    font-size: 32px;
  }

  &>p {
    color: white;
    font-size: 16px;
    margin: 32px 0px;
  }

  &>button {
    margin-right: 16px;
  }
}

.djc--hero-content-placeholder {
  width: 310px;
  height: 250px;
  background-color: $white;
}