$sidebar-width: 300px;

.djc--view-admin {
    font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: #EFEFEF;
    position: relative;

    .side-nav {
        padding-top: calc(#{$header-height} + 16px);
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: $sidebar-width;
        background-color: white;
        overflow-y: scroll;

        .section {
            border-top: 1px solid rgba($dark, 0.2);
            padding-top: 8px;
            margin-bottom: 16px;

            h4 {
                padding-left: 8px;
                font-size: 16px;
                color: rgba($dark, 0.6);
            }

            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding-left: 16px;
                box-sizing: border-box;
                height: 42px;
                color:rgba($dark, 0.6);
                font-weight: normal;

                i {
                    margin-right: 8px;
                }

                &.selected {
                    background-color: rgba($primary, 0.1);
                    border-left: 5px solid $primary;

                    padding-left: calc(16px - 5px);
                    color: $primary;
                    font-weight: bold;
                }

                &:hover {
                    background-color: rgba($primary, 0.05);
                    border-left: 5px solid $primary;
                    text-decoration: none;
                    padding-left: calc(16px - 5px);
                }
            }
        }
    }

    .content {
        margin-left: $sidebar-width;
        min-height: calc(100vh - #{$header-height});
        min-width: calc(100vw - #{$sidebar-width});
        padding: 32px;
        position: relative;

        .topbar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .title {
                display: inline-block;
                i, h1 {
                    font-size: 24px;
                    display: inline-block;
                }
    
                i {
                    margin-right: 8px;
                    color: $primary;
                }
            }

            margin-bottom: 16px;
        }

        .body {
            width: 100%;
            height: 100%;
            background-color: white;
            border: 1px solid rgba($dark, 0.2);

            &.fixed-height {
                position: absolute;
                left: 32px;
                top: calc(64px + 16px);
                width: calc(100% - 64px);
                height: calc(100% - 128px);
                overflow-y: hidden;

                .MuiTableContainer-root {
                    height: 100%;
                }
            }

            .section {
                padding: 32px;

                h4 {
                    font-size: 24px;
                    letter-spacing: 2px;
                }
            }
        }
    }
}

.admin-campaigns-create-img-container {
    width: 100%;
    display: inline-block;
    position: relative;
    border: 1px dotted rgba($dark, 0.2);
    padding: 8px;

    .dummy {
        margin-top: 75%;
    }

    .img-skeleton {
        position: absolute;
        left: 8px;
        right: 8px;
        bottom: 8px;
        top: 8px;
        background-color: rgba($dark, 0.2);
    }

    img {
        position: absolute;
        left: 8px;
        right: 8px;
        bottom: 8px;
        top: 8px;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        object-fit: cover;
    }
}

$ADMIN-DETAILS-DRAWER-WIDTH: 960px;
.details-drawer-content {
    width: $ADMIN-DETAILS-DRAWER-WIDTH;
    max-width: $ADMIN-DETAILS-DRAWER-WIDTH;
    overflow-x: hidden;
    padding: 32px;
}