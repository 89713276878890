.djc--sign-in-modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.djc--sign-in-modal-body {
    width: 900px;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;

    &:focus {
        outline: none;
    }

    &>.informations {
        width: 345px;
        height: 100%;
        background-color: $dark;
        padding: 32px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        color: $light;

        &>h2 {
            margin: 0;
            padding: 0;
        }

        &>.divider {
            width: 110px;
            height: 10px;
            background-color: $primary;
            border-radius: 16px;

            margin: 32px 0px;
        }

        &>p {
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
    }

    &>.form {
        padding: 50px 32px;
        width: 555px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;

        &>.invalid-credentials {
            font-weight: 500;
            font-size: 12px;
            color: red;
            width: 100%;
            text-align: center;
        }

        &>form {
            &>.MuiTextField-root {
                margin-bottom: 32px;
            }
        }

        &>.actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &>.divider {
            margin: 32px auto;
            width: 424px;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.1);
        }

        &>.create-account {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &>h3 {
                font-weight: 16px;
                font-weight: 500;
                color: $dark;
                margin: 0;
                padding: 0;
                margin-bottom: 32px;
            }

            &>button {
                margin-bottom: 32px;
                width: 350px;
            }

            &>span {
                color: rgba(0, 0, 0, 0.4);
                font-weight: 400;
                font-size: 14px;
                max-width: 320px;
                text-align: center;
            }
        }
    }
}
