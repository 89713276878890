.djc--view-campaigns {
    margin: 32px;

    .filters {
        background-color: white;
        border-bottom: 1px solid rgba($dark, 0.02);
        .filters--content {
            margin-top: 16px;
        }
    }

    .campaign-label {
        display: block;
        width: 100%;
        font-size: 16px;
        color: $dark;
        margin-top: 16px;
        padding-top: 16px;

        &:not(:first-child) {
            border-top: 1px solid rgba($dark, 0.1);
        }
    }

    .category-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .campaign-container {
            width: 210px;
            height: 225px;
            margin-right: 4px;
            margin-bottom: 24px;
            display: inline-block;
            opacity: 1;
            transition: 
                height 400ms ease-in-out, 
                width 400ms ease-in-out, 
                opacity 400ms ease-in-out;
            
            &.hide {
                overflow: hidden;
                height: 0px;
                width: 0px;
                opacity: 0;
                margin-right: 0px;
                margin-bottom: 0px;
            }

            .thumbnail {
                position: relative;
                width: 100%;
                height: 118px;
                background-color: $dark;
                
                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    object-fit: cover;
                }
            }

            .description {
                width: 100%;

                .title {
                    display: block;
                    display: -webkit-box;
                    max-height: calc(2 * 1.6rem);
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    font-size: 0.95rem;
                    font-weight: 500;
                    line-height: 1.6rem;
                    letter-spacing: normal;
                }

                span {
                    display: block;
                    font-size: 13px;
                    color:rgba($dark, 0.7)
                }
            }
        }
    }
}

@media (max-width: 880px) {
    .djc--view-campaigns {
        margin: 16px;
    }
}

@media (max-width: 576px) {
    .djc--view-campaigns {
        margin: 16px 0px;

        .category-container {
            
            .campaign-container {
                width: 100%;
                height: auto;
                margin-bottom: 4px;

                &:not(:first-child) {
                    padding-top: 8px;
                    border-top: 1px solid rgba($dark, 0.1);
                }

                .thumbnail {
                    width: 100%;
                    height: 150px;
                }
            }
        }
    }
}