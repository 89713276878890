$tobbar-height: 40px;
$bottom-panel-height: 260px;
$tabs-height: 36px;
$bottombar-height: 40px;

.djc--campaigns-participate-view {
    width: 100%;
    height: calc(100vh - #{$header-height});
    background-color: inherit;

    #test-case-desc-content {
        width: 100%;

        .test-case {
            &:not(:last-child) {
                border-bottom: 1px solid rgba($dark, 0.2);
            }
            margin-bottom: 32px;
            p {
                font-weight: bold;
            }
            .section {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 8px;

                span {
                    width: 100px;
                }

                pre {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    #split {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        position: relative;

        .bottombar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: $bottombar-height;
            background-color: $background;
            z-index: 100;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            button {
                margin-left: 8px;
                width: 180px;

                &:last-child {
                    margin-right: 8px;
                }
            }
        }

        .topbar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: $tobbar-height;
            width: 100%;
            background-color: $background;
            z-index: 100;
            
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex: 1;

            .campaign-title {
                font-size: 16px;
                color: $dark;
                font-weight: 500;
                margin-left: 8px;
                margin-bottom: 0;
            }

            .topbar--element {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-grow: 1;
                flex: 1;

                &.flex-end {
                    justify-content: flex-end;
                }
            }

            select {
                height: 30px;
                padding: 0 16px;
                max-width: 200px;
            }

            .btn-icon {
                background-color: transparent;
                color: rgba($dark, 0.6) !important;
                border: none;
                width: 32px;
                height: 32px;
                padding: 0;

                &>svg {
                    width: 75%;
                    height: 75%;
                }

                &:hover {
                    color: $dark !important;
                    &>svg {
                        color: $dark !important;
                    }
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .desc {
            position: relative;
            width: 100%;

            .desc-content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: $tobbar-height;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 16px;
                background-color: white;
                height: calc(100% - #{$tobbar-height} - #{$bottombar-height});
                overflow: auto;
                border: 1px solid rgba($dark, 0.2);
                border-left: none;

                .metadata {
                    width: 100%;
                    align-self: center;
                    margin-bottom: 32px;
                    border-bottom: 1px solid rgba($dark, 0.2);
                    padding-bottom: 16px;

                    .campaign-meta {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        color: rgba($dark, 0.6);

                        .rank-badge {
                            a {
                                color: white !important;
                            }
                        }

                        span {
                            margin-right: 16px;
                        }
                    }
                }
            }
        }
    
        .ide {
            position: relative;
            background-color: inherit;
            height: 100%;

            .content {
                border: 1px solid rgba($dark, 0.2);
                border-right: none;
                width: 100%;
                height: calc(100% - #{$tobbar-height} - #{$bottombar-height});
                position: absolute;
                top: $tobbar-height;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .react-codemirror2 {
                position: absolute;
                bottom: $bottom-panel-height;
                left: 0;
                right: 0;
                top: 0;
                height: calc(100% - #{$bottom-panel-height})
            }

            .bottom-panel {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: $bottom-panel-height;
                background-color: $background;
                
                .test-case-root {
                    width: 100%;
                    height: 64px;
                    
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    flex: 1;

                    span {
                        display: block;

                        &.test-name {
                            flex: 1;

                            .MuiSvgIcon-root {
                                width: 16px;
                                height: 16px;
                                margin-right: 8px;
                            }
                        }
                    }

                    button {
                        max-width: 260px;
                        flex: 1;
                    }
                    
                    &:not(:last-child) {
                        border-bottom: 1px solid rgba($dark, 0.2);
                    }
                }

                .console-output-content {
                    width: 100%;
                    height: 100%;
                    padding: 8px;
                    overflow: auto;

                    .status {
                        font-size: 16px;
                        &.success {
                            color: green;
                        }

                        &.error {
                            color: $red;
                        }
                    }

                    .section {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-bottom: 8px;

                        span {
                            &::after {
                                content: ":";
                            }

                            display: block;
                            width: 100px;
                            height: 100%;
                            font-size: 14px;
                        }

                        pre {
                            background-color: rgba($dark, 0.1);
                            border: 1px solid rgba($dark, 0.2);
                            padding: 4px;
                            width: 100%;
                            margin: 0;
                            max-height: 100px;
                            tab-size: 2;
                        }
                    }
                }

                .default-output-message {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;

                    h3 {
                        display: block;
                        width: 100%;
                        margin: auto;
                        color: rgba($dark, 0.3);
                    }
                }

                .tab-panel {
                    position: absolute;
                    top: $tabs-height;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: white;
                    overflow: auto;
                    padding: 8px;
                }

                .MuiTabs-root {
                    height: $tabs-height;
                    min-height: $tabs-height;

                    .MuiTabs-indicator {
                        background-color: white;
                        width: 0;
                    }

                    .MuiTabs-flexContainer {
                        height: 100%;
                        border-bottom: 1px solid rgba($dark, 0.2);
                        z-index: 1;

                        .MuiButtonBase-root {
                            min-width: 0;
                            height: 100%;
                            min-height: 0;
                            background-color: $background;
                            border-right: 1px solid rgba($dark, 0.2);
                            text-transform: capitalize;
                            font-size: 12px;
                            font-weight: normal;
                            letter-spacing: normal;
                            
                            .MuiTab-wrapper {
                                align-items: flex-start;
                            }

                            &:focus {
                                outline: none;
                            }

                            &.Mui-selected {
                                background-color: white;
                                height: calc(100% + 1px);
                                z-index: 2;
                            }
                        }
                    }
                }
            }
        }
    }
}
