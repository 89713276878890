.djc--view-user {
    width: "100%";
    margin: 32px;

    .section {
        background-color: white;
        padding: 16px;
    }

    .avatar-mobile {
        width: 64px;
        height: 64px;
    }

    .mobile-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        h3 {
            font-weight: 400;
            padding-left: 16px;
        }
    }
}