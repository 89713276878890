.djc--view-homepage {
    font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    margin-top: -#{$header-height};

    .hero-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1 !important;
        object-fit: cover;
        background-size: cover;
        filter: brightness(0.5);
    }

    .hero-container {
        position: relative;
        width: 100vw;
        height: 100vh;
        z-index: 0 !important;
        padding-top: 100px;

        .heading {
            width: 100%;
            max-width: 1280px;
            height: 100%;
            margin: 0 auto;
            z-index: 1000 !important;
            color: #f8d3ff;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                letter-spacing: 16px;
                margin-bottom: 32px;
            }

            h1 {
                font-size: 64px;
                letter-spacing: 8px;
                margin-bottom: 28px;
            }

            .orness-battle-dev-img-container {
                width: 100%;
                height: 30vh;
                position: relative;

                img {
                    display: block;
                    margin: 0 auto;
                    width: auto;
                    height: 100%;
                }
            }

            .countdown {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;

                margin-bottom: 128px;

                .number {
                    width: 130px;
                    min-width: 80px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &:not(:first-child) {
                        margin-left: 32px;
                    }

                    .time {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        h3 {
                            text-align: center;
                            font-size: 52px;
                            padding: 0;
                            display: block;
                            width: auto;
                            letter-spacing: 8px;
                        }
                    }

                    .time-desc {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        h4 {
                            width: auto;
                            display: block;
                            text-align: center;
                            margin: 0;
                            font-size: 18px;
                            letter-spacing: 14px;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .header-btn-learn-more {
                height: 42px;
                border-radius: 50px;
                text-transform: uppercase;
                width: 300px;
                border: none;

                background: rgb(44, 198, 223);
                background: -moz-linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                background: -webkit-linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                background: linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2cc6df", endColorstr="#b05287", GradientType=1);

                &:hover {
                    color: #683579 !important;
                }
            }
        }
    }

    .working-man-illustration {
        width: 100%;
        height: auto;
    }

    .lifting-man-illustration {
        max-height: 400px;
        float: right;
    }

    .shipping-package-illustration {
        max-height: 400px;
        float: left;
    }

    section {
        min-height: 200px;
        padding: 64px;

        .section-title {
            width: 100%;
            text-align: center;
            color: $primary;
            font-size: 24px;
            letter-spacing: 4px;
            margin-bottom: 64px;
            text-transform: capitalize;
        }

        .content {
            max-width: 940px;
            margin: 0 auto;
        }
    }

    .programming-languages {
        background-color: $dark;
        min-height: 100px;
        width: 100%;
        padding: 32px;

        h2 {
            color: $primary;
            letter-spacing: 4px;
            width: 100%;
            text-align: center;
            margin-bottom: 32px;
        }

        .content {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;

            i {
                display: block;
                font-size: 48px;
                width: 48px;
                height: 48px;
                color: $light;
            }
        }
    }

    .and-now {
        width: 100%;
        min-height: 800px;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .background {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            filter: brightness(0.4);

            z-index: -1;
        }

        .content {
            z-index: 1;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                font-size: 64px;
                letter-spacing: 8px;
                color: $primary;
                text-transform: uppercase;
                width: 100%;
                text-align: center;
            }

            p {
                color: $white;

                margin-bottom: 32px;
                max-width: 80%;
                text-align: center;
            }

            .btn-register-now {
                height: 42px;
                border-radius: 50px;
                text-transform: uppercase;
                width: 300px;
                border: none;

                background: rgb(44, 198, 223);
                background: -moz-linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                background: -webkit-linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                background: linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2cc6df", endColorstr="#b05287", GradientType=1);

                &:hover {
                    color: #683579 !important;
                }
            }

            .battle-dev-2022--arguments-container {
                margin-bottom: 32px;
            }

            .battle-dev-2022--argument {
                width: 100%;
                padding: 32px 32px 16px 32px;
                background-color: rgba($dark, 0.5);
                margin-bottom: 16px;
                height: 290px;

                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                text-align: center;

                .top {
                    height: 40%;

                    i {
                        font-size: 64px;
                        margin-bottom: 8px;
                        color: $primary;
                    }

                    h3 {
                        font-size: 18px;
                        color: $primary;
                    }
                }

                p {
                    height: 50%;
                    font-size: 14px;
                    color: $light;
                    margin: 0;
                }
            }
        }
    }

    .illustration {
        max-height: 400px;
    }

    @media screen and (max-width: 1280px) {
        .hero-container {
            .heading {
                max-width: 640px;
                transform: translateY(-5vh);

                h3 {
                    font-size: 18px;
                    margin-bottom: 64px;
                }

                h1 {
                    font-size: 42px;
                    margin-bottom: 64px;
                }

                .orness-battle-dev-img-container {
                    height: 300px;
                }

                .countdown {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 32px;
                    justify-content: space-around;

                    .number {
                        width: 50px;
                        max-width: 50px;
                        margin-left: 0px;

                        .time {
                            h3 {
                                font-size: 24px;
                                letter-spacing: 4px;
                                margin-bottom: 0;
                            }
                        }

                        .time-desc {
                            h4 {
                                font-size: 18px;
                                letter-spacing: 4px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .and-now {
            height: 500px;

            .content {
                h2 {
                    font-size: 32px;
                    letter-spacing: 4px;
                    margin-bottom: 32px;
                }

                p {
                    color: $white;

                    margin-bottom: 32px;
                }

                .btn-register-now {
                    height: 42px;
                    border-radius: 50px;
                    text-transform: uppercase;
                    width: 300px;
                    border: none;

                    background: rgb(44, 198, 223);
                    background: -moz-linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                    background: -webkit-linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                    background: linear-gradient(25deg, rgba(44, 198, 223, 1) 0%, rgba(176, 82, 135, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2cc6df", endColorstr="#b05287", GradientType=1);

                    &:hover {
                        color: #683579 !important;
                    }
                }
            }
        }

        .illustration {
            max-height: 350px;
        }
    }

    @media screen and (max-width: 990px) and (min-width: 681px) {
        .battle-dev-2022--argument {
            justify-content: flex-start !important;
            padding: 16px !important;
            height: 230px !important;

            .top {
                height: auto !important;

                i {
                    font-size: 32px !important;
                }

                margin-bottom: 16px;
            }

            p {
                height: auto !important;
                font-size: 12px !important;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .battle-dev-2022--argument {
            flex-direction: row !important;
            justify-content: space-evenly !important;
            align-items: center !important;
            padding: 8px !important;
            height: auto !important;
            width: 100% !important;

            .top {
                height: 100% !important;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 20% !important;

                i {
                    width: 100%;
                    text-align: center;
                    display: block;
                    font-size: 32px !important;
                    margin: 0 !important;
                    margin-bottom: 8px !important;
                }

                h3 {
                    display: block !important;
                    margin: 0 !important;
                    font-size: 12px !important;
                    text-align: center;
                    width: 100%;
                }
            }

            p {
                height: auto !important;
                font-size: 10px !important;
                width: 60%;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: 680px) {
        .hero-container {

            .heading {
                max-width: 300px;

                .orness-battle-dev-img-container {
                    width: 350px;
                    height: auto;

                    img {
                        width: 350px;
                        height: auto;
                    }
                }

                .countdown {

                    .number {
                        &.secs {
                            display: none;
                        }
                    }
                }
            }
        }

        section {
            padding: 64px 8px;
        }

        .programming-languages {
            padding: 32px 16px;

            h2 {
                color: $white;
                letter-spacing: 4px;
                width: 100%;
                font-size: 20px;
                text-align: center;
                margin-bottom: 32px;
            }

            .content {
                i {
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .illustration {
            max-height: 250px;
        }
    }

    @media screen and (max-height: 680px) {
        .hero-container {
            padding-top: $header-height;

            .heading {
                height: 100%;

                h3 {
                    letter-spacing: 16px;
                    margin-bottom: 16px;
                    font-size: 16px;
                }

                .orness-battle-dev-img-container {
                    height: 35vh;
                }

                .countdown {
                    margin-bottom: 32px;

                    .number {
                        .time {
                            h3 {
                                font-size: 32px;
                            }
                        }

                        .time-desc {
                            h4 {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}