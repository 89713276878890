$PAD_HEIGHT: 16px;
$PADDING_ROW: 8px $PAD_HEIGHT;

.djc--component-nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .bg-over {
        display: none; 
        z-index: 1000;
    }

    &.mobile {
        margin-bottom: calc(#{$PAD_HEIGHT} * 4);
        z-index: 500;

        .bg-over {
            display: block;
            position: absolute;
            top: $header-height;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(darken($secondary, 5), 0.2);
            
            transition: background-color 0.2s ease-in-out;

            &.hide {
                background-color: rgba(darken($secondary, 5), 0);
                z-index: -100;
            }
        }

        .content {
            background-color: white;
            z-index: 100;
            position: fixed;
            top: $header-height;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
    
            .current {
                border-bottom: 1px solid rgba($dark, 0.1);
                width: 100%;
                padding: $PADDING_ROW;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &:focus {
                    outline: none !important;
                }
            }
    
            .list-links {
                &.hide {
                    display: none;
                }

                background-color: white;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
    
                .list-link-row {
                    width: 100%;
                    padding: $PADDING_ROW;
                    color: rgba($dark, 0.5);
                    font-weight: normal;
                    
                    &:hover {
                        background-color: rgba($primary, 0.05);
                        text-decoration: none;
                    }
    
                    &:last-child {
                        border-bottom: 1px solid rgba($dark, 0.1);
                    }
                }
            }
        }
    }
}