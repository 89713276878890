.djc--view-cgi {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        width: 100%;
        max-width: 960px;
        margin: 32px;
    }

    h2:not(:first-child) {
        margin-top: 64px;
        margin-bottom: 16px;
    }

    h3 {
        margin-top: 16px;
        margin-left: 16px;
    }
}