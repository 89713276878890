.djc--error {
    width: 100%;
    height: calc(100vh - #{$header-height});
    max-height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $dark;

    .left {
        width: 600px;

        h1 {
            font-weight: bold;
            font-size: 158px;
        }

        p {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .right {
        width: 500px;
        display: flex;
        justify-content: center;

        img {
            max-height: 400px;
        }
    }
}