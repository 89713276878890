$BORDER-RADIUS: 8px;

.scoreboard {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .scoreboard-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 32px;
        background-color: darken($secondary, 8);
        margin-bottom: 8px;
        border-radius: $BORDER-RADIUS;

        position: relative;
        z-index: 1;
        padding: 16px;
        padding-bottom: calc(16px + 5px);
        color: white;

        .scoreboard-row-bg-progress-bg {
            position: absolute;
            height: 10px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-color: darken($secondary, 3);

            border-bottom-left-radius: $BORDER-RADIUS;
            border-bottom-right-radius: $BORDER-RADIUS;
        }

        .scoreboard-row-bg {
            position: absolute;
            height: 10px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            border-top-right-radius: $BORDER-RADIUS;
            border-bottom-right-radius: $BORDER-RADIUS;
            border-bottom-left-radius: $BORDER-RADIUS;

            &.scoreboard-row-bg-0 {
                border-top-right-radius: 0;
            }
        }

        .label, .value {
            display: flex;
            width: fit-content;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .gravatar {
                margin-right: 8px;
            }
        }

        &.scoreboard-row-0, &.scoreboard-row-1, &.scoreboard-row-2 {
            .label {
                margin-left: calc(64px - 16px);
            }
        }


        .ribbon {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 64px;
            z-index: 2;

            border-bottom-right-radius: $BORDER-RADIUS;
            border-bottom-left-radius: $BORDER-RADIUS;

            &.hide {
                display: none;
            }

            .behind-ribbon {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: darken(white, 4);

                clip-path: polygon(100% 100%, 0% 0%, 0% 100%);
                z-index: -2;
            }

            .front-ribbon {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 0;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: white;

                    clip-path: polygon(100% 0, 0% 0, 0% 100%);
                    
                }
                filter: drop-shadow(-1px 2px 3px rgba(50, 50, 0, 0.5));
            }

            &.ribbon-0 {
                .front-ribbon {
                    &::after {
                        background-color: $gold;
                    }
                }
                .behind-ribbon {
                    background-color: $gold;
                }
            }

            &.ribbon-1 {
                .front-ribbon {
                    &::after {
                        background-color: $silver;
                    }
                }
                .behind-ribbon {
                    background-color: $silver;
                }
            }

            &.ribbon-2 {
                .front-ribbon {
                    &::after {
                        background-color: $copper;
                    }
                }
                .behind-ribbon {
                    background-color: $copper;
                }
            }
        }
    }
}