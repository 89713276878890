button:not(.MuiButtonBase-root):not(.custom):not(.Toaster__alert_close) {
  min-width: 160px;
  height: 34px;
  border-radius: 0;
  background-color: $primary;
  color: white;
  border: 2px solid $primary;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: all 0.1s;

  &>.MuiCircularProgress-root {
    margin-left: 8px;
    color: $dark;
  }

  &.rainbow {
    background: linear-gradient(25deg, #2cc6df 0%, #b05287 100%);
    border: none;
    color: rgba(white, 1);
    transition: all 0.1s ease-in-out;

    &:hover:not(:disabled) {
      color: $primary !important;
    }

    &:disabled {
      background: linear-gradient(25deg, rgba(#2cc6df, 0.5) 0%, rgba(#b05287, 0.5) 100%);
    }
  }

  &:hover:not([disabled]) {
    color: $primary;
    background-color: rgba(0, 0, 0, 0)
  }

  &:disabled {
    background-color: rgba($primary, 0.5);
    border-color: transparent;
    cursor: not-allowed;
  }

  &.outlined {
    color: rgba($primary, 0.8);
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgba($primary, 0.8);

    &:hover {
      &:not(:disabled) {
        color: $primary;
        border: 2px solid $primary;
      }
    }

    &:disabled {
      color: rgba($primary, 0.4) !important;
      border: 2px solid rgba($primary, 0.4) !important;
    }
  }

  &.white {
    color: rgba($white, 0.7);
    border-color: rgba($white, 0.7);

    &:hover {
      color: $white;
      border-color: $white;
    }
  }

  &.white-transition {
    &:hover {
      color: $white;
      border-color: $white;
    }
  }

  &:focus {
    outline: none;
    border: 2px solid $primary;
  }

  &.red {
    background-color: $red;
    color: white;
    border-color: $red;

    &:hover {
      color: $red;
    }
  }

  &.outlined.red {
    color: rgba($red, 0.5);
    border-color: rgba($red, 0.5);

    &:hover {
      color: $red;
      border-color: $red;
    }
  }

  svg {
    width: 0.8em;
    height: 0.8em;
    margin-left: 16px;
  }
}

.btn-avatar {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.btn-social-icon {
  &:hover {
    color: white !important;
  }
}

.btn-social-icon.btn-xs>:first-child {
  font-size: 0.9em !important;
}

.btn-social-icon.btn-instagram {
  background: #f09433 !important;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) !important; 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) !important; 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ) !important;
}

.simple-btn-icon {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;

  i {
    font-size: 1.4rem;
  }
}