.djc--view-about {
    font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    header {
        width: 100%;
        height: 480px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: -1;
            object-fit: cover;
            -webkit-filter: grayscale(70%); /* Safari 6.0 - 9.0 */
            filter: grayscale(70%);
        }

        h1 {
            z-index: 2;
            display: block;
            color: $primary;
        }
    }

    .content {
        max-width: 940px;
        margin: 0 auto;
    }

    section {
        width: 100%;
        min-height: 200px;
        padding: 16px;
        margin-bottom: 16px;
        float: left;

        display: block;

        h3 {
            color: $primary;
            margin-bottom: 16px;
            letter-spacing: 1px;
        }

        .img-container {
            width: 100%;
            max-width: 40%;
            height: auto;
            position: relative;
            float: left;
            margin-right: 16px;
            margin-bottom: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        p {
            text-align: justify;
            float: right;
            display: contents;
            margin-bottom: 16px;
        }

        &.img-right {
            .img-container {
                float: right;
                margin-right: 0;
                margin-left: 16px;
            }

            p {
                float: left;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        header {
            height: 400px;
            h1 {
                font-size: 32px;
            }
        }
    }

    @media screen and (max-width: 990px) and (min-width: 681px) {
        header {
            height: 350px;
            h1 {
                font-size: 24px;
            }
        }
    }

    @media screen and (max-width: 681px) {
        header {
            height: 300px;
            h1 {
                font-size: 18px;
            }
        }
    }
}