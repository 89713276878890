.djc--view-articles-list {
    width: 100%;
    height: 100%;
    padding: 64px;

    .container {
        width: 100%;
        height: 100%;
        max-width: 1280px;
        min-height: 300px;
        margin: 0 auto;
    
        .articles {
            width: 100%;
            height: 100%;

            .article {
                width: 100%;
                min-height: 300px;
                border: 1px solid rgba($dark, 0.1);
                margin-bottom: 32px;

                .thumbnail {
                    width: 100%;
                    height: 200px;

                    img {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        object-fit: cover;
                    }
                }

                .article-content {
                    padding: 16px;
                    
                    .tags-container {
                        width: 100%;
                        margin: 0;

                        .tag {
                            min-width: 40px;
                            width: auto;
                            padding: 0;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }
    
                            span {
                                width: auto;
                                font-size: 10px;
                                font-weight: 500;
                                padding: 4px;
                                background-color: $light;
                                color: white;
                            }
    
                            &.news {
                                span {
                                    background-color: $primary;
                                    color: white;
                                }
                            }

                            &.event, &.events {
                                span {
                                    background-color: $secondary;
                                    color: white;
                                }
                            }
                        }
                    }

                    .container-fluid {
                        padding: 0;
                    }
                    
                    .title {
                        display: block;
                        display: -webkit-box;
                        max-height: calc(2 * 1.6rem);
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        -webkit-line-clamp: 2;
                        font-size: 0.95rem;
                        font-weight: 500;
                        line-height: 1.6rem;
                        letter-spacing: normal;
                        margin-bottom: 16px;
                    }

                    .description {
                        display: block;
                        display: -webkit-box;
                        max-height: calc(4 * 1.4rem);
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        -webkit-line-clamp: 4;
                        font-size: 0.9rem;
                        font-weight: 400;
                        line-height: 1.4rem;
                        letter-spacing: normal;
                        color: rgba($dark, 0.7);
                    }
                }
            }
        }
    }
}

@media (max-width: 880px) {
    .djc--view-articles-list {
        width: 100%;
        height: 100%;
        padding: 16px 8px;
    }
}

@media (max-width: 425px) {
    .djc--view-articles-list {
        width: 100%;
        height: 100%;
        padding: 8px;

        .container {
            padding: 0;

            .articles {
                padding: 0;

                .article {
                    min-height: 150px;
                    
                    .article-content {
                        padding: 8px;
                    }
                }
            }
        }
    }
}