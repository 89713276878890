.markdown {
    color: $dark;
    width: 100%;
    min-width: 300px;

    p, li, span, code, ol, ul, strong, b, u, i {
        font-size: 14px;
    }

    h1, h2, h3, h4, h5, h6 {
        &:not(:first-child) {
            padding-top: 8px;
        }

        margin-bottom: 16px;
    }

    h1 {
        font-size: 24px;
        margin-bottom: 16px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 16px;
    }

    ul {
        list-style-type: none;

        li {
            &::before {
                content: "-";
                margin-right: 8px;
            }

            input {
                margin-right: 8px;
            }
        }
    }

    p {
        margin-bottom: 16px;
    }

    code {
        color: $primary;
    }
    
    pre {
        background-color: rgba($dark, 0.1);
        border: 1px solid rgba($dark, 0.2);
        padding: 8px;

        code {
            color: $dark;
        }
    }

    blockquote {
        padding: 8px 16px;
        background-color: white;
        border-left: 3px solid $primary;
        border-top: 1px solid rgba($dark, 0.1);
        border-right: 1px solid rgba($dark, 0.1);
        border-bottom: 1px solid rgba($dark, 0.1);
        p {
            white-space: pre-wrap;
        }
        ul {
            padding: 0;
        }
        * {
            margin: 0;
        }
    }

    table {
        width: 100%;

        thead {
            background-color: $primary;
            color: white;
            text-align: center;
            font-weight: normal;
            font-size: 14px;

            tr {
                height: 40px;
            }
        }

        tbody {
            tr {
                &:not(:last-child) {
                    border-bottom: 1px solid rgba($dark, 0.2);
                }

                &:nth-child(odd) {
                    background-color: rgba($primary, 0.05);
                }
                
                td {
                    font-size: 12px;
                    padding: 4px 16px;
                    vertical-align: baseline;
                    text-align: center;
                
                    &:first-child {
                        padding-left: 8px;
                    }

                    &:last-child {
                        padding-right: 8px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        h1, h2, h3, h4, h5, h6 {
            border-color: transparent;
        }
    }

    .btn-md-default {
        display: block;
        height: 42px;
        border-radius: 50px;
        text-transform: uppercase;
        text-align: center;
        line-height: 42px;
        color: white;
        width: 300px;
        border: none;
        background: #2cc6df;
        background: linear-gradient(25deg, #2cc6df 0%, #b05287 100%);
        text-decoration: none;
        transition: color 0.1s ease-in-out;
        margin: 0 auto;

        &:hover {
            color: #683579;
            text-decoration: none;
        }

        &.alignment-left {
            margin: 0 0;
        }

        &.alignment-center {
            margin: 0 auto;
        }

        &.alignment-right {
            margin: 0 100%;
        }
    }
}