.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(6px, -6px) scale(0.75) !important;
    background-color: white;
    padding: 0 6px;
}

.MuiOutlinedInput-notchedOutline {
    top: 0 !important;
}

.MuiAccordion-root:not(.accordion-code-history) {
    &::before {
        background-color: transparent !important;
    }

    border-color: transparent !important;
    box-shadow: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    border-bottom: 1px solid rgba($dark, 0.2) !important;

    margin-bottom: 16px !important;

    .MuiAccordionDetails-root {
        padding: 0 !important;

        p {
            margin: 0 !important;
            margin-bottom: 16px !important;
            font-size: 14px;
        }

        blockquote {
            * {
                margin: 0 !important;
            }
        }
    }

    .MuiAccordionSummary-root {
        min-height: 16px !important;
        padding-left: 0 !important;
        padding-bottom: 0px !important;
        color: $primary !important;
        align-items: flex-start !important;

        .MuiAccordionSummary-content {
            margin: 0 !important;

            p {
                margin: 0 !important;
                font-size: 14px;
                letter-spacing: normal;                
            }
        }

        .MuiAccordionSummary-expandIcon {
            padding: 0 !important;
            color: $primary !important;
        }
    }
}

.MuiCircularProgress-svg {
    margin: 0 !important;
}

.MuiCircularProgress-root {
    width: 0.8rem !important;
    height: 0.8rem !important;

    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}