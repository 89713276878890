.djc--view-articles-create {
    width: 100%;
    height: 100%;

    .container {
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        margin-top: 64px;

        .section {
            &>h4 {
                display: block;
                color: $dark;
                font-size: 18px;
                padding-bottom: 8px;
                border-bottom: 1px solid rgba($dark, 0.2);
                margin-bottom: 16px;
            }
    
            &:not(:last-child) {
                margin-bottom: 32px;
            }
    
            .row {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
    
                .input {
                    .MuiTextField-root {
                        &:not(:first-child) {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }

        .editor {
            width: 100%;

            min-height: 40vh;
            max-height: 80vh;
            overflow-y: auto;
            border-left: 1px solid rgba($dark, 0.2);
            border-right: 1px solid rgba($dark, 0.2);
        }
    }
}